import { Decimal } from 'decimal.js'
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
import i18n from './i18n.js'
// 加
export function add(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.add(yy).toNumber()
}

// 减
export function sub(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.sub(yy).toNumber()
}

// 乘
export function mul(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.mul(yy).toNumber()
}

// 除
export function div(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.div(yy).toNumber()
}
//截取4位小数
export function  get2String(val){
  const valNum=val+''
  let indexOf=valNum.indexOf('.')
  if(indexOf===-1){
    return valNum
  }else{
    return valNum.slice(0,indexOf+3)
  }
}
//截取4位小数
export function  get4number(val){
  const valNum=val+''
  let indexOf=valNum.indexOf('.')
  if(indexOf===-1){
    return valNum
  }else{
    return valNum.slice(0,indexOf+5)
  }
}
export function  get5number(val){
  const valNum=val+''
  let indexOf=valNum.indexOf('.')
  if(indexOf===-1){
    return valNum
  }else{
    return valNum.slice(0,indexOf+6)
  }
}
// 时间时区处理
export function  toHHmmss(timestamp, offset) {
  // 需要显示的时区
  let targetTimezone;
  // if(baseApi==='http://192.168.5.213:8085'){
  //   targetTimezone = Number(offset )
  // }else{
  //   targetTimezone = Number(offset - 8)
  // }
  targetTimezone = Number(offset )
  // 先把时间戳对应转行并往utc-0处理  转换为当前时区对应的utc-0的时间戳数值
  let utc0 = timestamp + new Date(timestamp).getTimezoneOffset() * 60000;
  // 按照时区转换到时区对应的时间戳
  let resultTimestamp = utc0 + (3600000 * targetTimezone)

  // 实际用到的时间戳
  var data = new Date(resultTimestamp)
  var year = data.getFullYear()
  var month = data.getMonth() + 1
  month = month < 10 ? '0' + month : month
  var day = data.getDate()
  day = day < 10 ? '0' + day : day
  var hours = data.getHours()
  hours = hours < 0 ? 24 + hours : hours
  var minutes = data.getMinutes()
  minutes = minutes < 0 ? 60 + minutes : minutes
  var seconds = data.getSeconds()
  seconds = seconds < 0 ? 60 + seconds : seconds

  var time  =
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
  return time
}

// 订单状态回显
export function getSubLevel(val){
    let text
    switch(val) {
      case 0 :
        text = i18n.global.t('team.pthy')
        break
      case 1 :
        text = i18n.global.t('team.swhy')
        break
      case 11 :
        text = 'VIP1'
        break
      case 12 :
        text = 'VIP2'
        break
      case 13 :
        text = 'VIP3'
        break
      case 14 :
        text = 'VIP4'
        break
      case 15 :
        text = 'VIP5'
        break
      case 16 :
        text = 'VIP6'
        break
      case 17 :
        text = 'VIP7'
        break
      case 18 :
        text = 'VIP8'
        break
      case 19 :
        text = 'VIP9'
        break
      case 20 :
        text = 'VIP10'
        break

    }
    return text
}

// 加密
export function getPublicKey(val){
  const publicKey='MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMaLUBt5aikvYXNCugZaEfaZYgZl1eD1LgbYLPvzXqKKXL7IzDTedVi10g3rnQFQobfc0JWaaCj7Ll9OQCTRxHMCAwEAAQ=='
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)

  return encryptor.encrypt(val)
}
